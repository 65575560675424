








































































import Vue from "vue";
import Component from "vue-class-component";
import { TradeshowCompanyObject } from "@/types/interfaces";
import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard.vue";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import UpcomingStreams from "@/components/exhibitor-profile/UpcomingStreams.vue";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        ExhibitorResultCard,
        ExhibitorLogoCard,
        Spinners,
        PageTitleHeader,
        UpcomingStreams
    }
})
export default class TradeshowGridList extends Vue {
    isLoading = true;

    get pageConfig() {
        return this.$store.getters.getPageOptions("tradeshow") || {};
    }

    get textHeader() {
        return this.pageConfig?.textHeader;
    }

    get tradeshowCompanies() {
        const companies = tradeshowStore.allTradeshowCompanies;

        return companies.sort(
            (cur: TradeshowCompanyObject, nxt: TradeshowCompanyObject) => {
                const curName = cur.companyName
                    ? cur.companyName
                    : cur.name
                    ? cur.name
                    : "";
                const nextName = nxt.companyName
                    ? nxt.companyName
                    : nxt.name
                    ? nxt.name
                    : "";

                return curName.localeCompare(nextName);
            }
        );
        // return companies;
        // return Vue.prototype.MgSortByProperty(companies, "companyName");
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }

    get pageDecorImage(): string {
        let returnValue = "";
        if (
            this.pageConfig.decorImages &&
            this.pageConfig.decorImages.pageBackground
        ) {
            returnValue = `${process.env.BASE_URL}bg/${this.pageConfig.decorImages.pageBackground}`;
        }
        return returnValue;
    }

    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
    }

    getLogoImage(exhibitor: TradeshowCompanyObject) {
        let returnPath = exhibitor.imageLogo || exhibitor.images?.logo;
        if (!returnPath) {
            return (returnPath = `${process.env.BASE_URL}bg/${this.pageConfig.defaultLogo}`);
        } else {
            return returnPath;
        }
    }
}
